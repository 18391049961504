.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#shadow_transparent {
  filter: drop-shadow(0px 20px 20px #0a0a0a);
  -webkit-filter: drop-shadow(0px 20px 20px #0a0a0a);
}

#root {
}

.nav-link {
  transition: 0.35s;
}

.drop {
  animation: drop 1s ease;
}

.dropdown-menu.show {
  background-color: #1f1f1f;
}
a.dropdown-item {
  color: #a0a0a0;
  background-color: #1f1f1f;
  transition: 0.35s;
}
a.dropdown-item.hover {
  color: white;
  background-color: #1f1f1f;
  transition: 0.35s;
}
a.dropdown-item.active {
  color: #a0a0a0;
  background-color: #1f1f1f;
  transition: 0.35s;
}
a.dropdown-item.active:hover {
  color: white;
  background-color: #1f1f1f;
  transition: 0.35s;
}
a.dropdown-item:active {
  color: #a0a0a0;
  background-color: #1f1f1f;
  transition: 0.35s;
}
.dropdown-menu.show {
  height: 0px;
  padding: 0;
}

#navMenuOpen:hover {
  background-color: grey;
}

.footer_padding {
  padding-bottom: 60px;
  transition: 0.35s;
}

.btn_fixed_bottom {
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: 0.35s;
}
a.btn_fixed_bottom.btn.btn-dark {
  background-color: #1f1f1f;
  animation: btn_light 3s infinite ease-out;
}
a.btn_fixed_bottom.btn.btn-dark:hover {
  color: #1f1f1f;
  background-color: #00e7a1;
  animation: 0;
}

.arrow {
  animation: drop 1s infinite ease-out;
}

img.bluetoothMotion {
  animation: arrow_drop 2s infinite ease;
}
svg.popOnDisplay1 {
  animation: pop1 3s infinite ease;
}
svg.popOnDisplay2 {
  animation: pop2 3s infinite ease;
}
svg.popOnDisplay3 {
  animation: pop3 3s infinite ease;
}
#loadinglogo {
  animation: loadinglogo 0.3s infinite ease;
}

@media screen and (min-width: 992px) {
  .footer_padding {
    padding-bottom: 110px;
  }

  .btn_fixed_bottom {
    bottom: 50px;
    max-width: 800px;
    margin: auto;
  }

  .arrow_fixed_bottom {
    bottom: 10px;
    animation: drop 1s infinite ease;
  }
}

@keyframes btn_light {
  0% {
    color: #007753;
  }
  50% {
    color: #00ffb3;
  }
  100% {
    color: #007753;
  }
}

@keyframes drop {
  0% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes arrow_drop {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.9;
  }
  70% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
@keyframes pop1 {
  0% {
    opacity: 0.2;
    font-size: 8vmin;
    transform: translate(-5vmin, 3vmin) scale(1.2, 1);
  }
  100% {
    opacity: 0;
    font-size: 12vmin;
    transform: translate(-5vmin, 1vmin) scale(1.2, 1);
  }
}
@keyframes pop2 {
  0% {
    opacity: 0.2;
    font-size: 8vmin;
    transform: translate(-40vmin, -11vmin) scale(1.2, 1);
  }
  100% {
    opacity: 0;
    font-size: 12vmin;
    transform: translate(-40vmin, -13vmin) scale(1.2, 1);
  }
}
@keyframes pop3 {
  0% {
    opacity: 0.2;
    font-size: 8vmin;
    transform: translate(-5.5vmin, -11.5vmin) scale(1.2, 1);
  }
  100% {
    opacity: 0;
    font-size: 12vmin;
    transform: translate(-5.5vmin, -13.5vmin) scale(1.2, 1);
  }
}
@keyframes loading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
}
